// extracted by mini-css-extract-plugin
export var accordionTitle = "sample-module--accordionTitle--9595a";
export var actionContainer = "sample-module--actionContainer--ec1f4";
export var addToButtonContainer = "sample-module--addToButtonContainer--f991f";
export var attributeContainer = "sample-module--attributeContainer--205a5";
export var content = "sample-module--content--f4678";
export var description = "sample-module--description--bf8fb";
export var details = "sample-module--details--fd8cb";
export var gallery = "sample-module--gallery--3cdbb";
export var heartFillContainer = "sample-module--heartFillContainer--b0d52";
export var hide = "sample-module--hide--c57f4";
export var information = "sample-module--information--1be85";
export var informationContainer = "sample-module--informationContainer--a633f";
export var location = "sample-module--location--42f93";
export var priceContainer = "sample-module--priceContainer--18984";
export var priceLabel = "sample-module--priceLabel--db630";
export var quantityContainer = "sample-module--quantityContainer--8d750";
export var root = "sample-module--root--de31a";
export var show = "sample-module--show--f3f43";
export var sizeContainer = "sample-module--sizeContainer--71af2";
export var suggestionContainer = "sample-module--suggestionContainer--e6ea8";
export var title = "sample-module--title--58bc6";
export var wishlistActionContainer = "sample-module--wishlistActionContainer--8bc68";